import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Card, Tag, Typography, Space, Divider, Button, message } from 'antd';
import { WebSocketProvider, WebSocketContext } from '@/components/WebSocket';
import { OperateStatusMap } from '@/utils/statusMap';
import { getDriverMarket } from '@/api';

import './style.less';
import useAuthButton from '@/hooks/useAuthButton';

const { useMessage } = message;

export interface DriveMarketProps {

}

export interface DriverMarketType {
  icon: string;
  id: string;
  is_internal: boolean;
  manual: string;
  name: string;
  description: string;
  operate_status: 'installing' | 'default' | 'installed' | 'uninstall';
  version: string;
}

const DriveMarket: React.FC<DriveMarketProps> = () => {
  const [items, setItems] = useState<DriverMarketType[]>([]);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = useMessage();

  const key = 'updatable';

  const { ws } = useContext(WebSocketContext);

  const updateItemStatus = useCallback(
    (id: string, status: DriverMarketType['operate_status']) => {
      setItems(
        [...items].map((item) => {
          if (item.id === id) {
            item.operate_status = status;
          }
          return item;
        }),
      );
    },
    [items],
  );

  useEffect(() => {
    setLoading(true);
    getDriverMarket<DriverMarketType>()
      .then((resp) => {
        if (resp.success) {
          setItems(resp.result.list);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const { checkPermissionDisabled } = useAuthButton();

  return (
    <>
      {contextHolder}
      <div className="my-service p20">
        <Card loading={loading} bordered>
          <div className="responsive-layout">
            {items.map((item) => {
              const { color, icon, text } = OperateStatusMap[item.operate_status];
              return (
                <Card key={item.id} className="card-item" size="small" hoverable>
                  <Tag color={color} icon={icon}>
                    {text}
                  </Tag>
                  <div className="flex-center">
                    <div className="logo" style={{ backgroundImage: `url(${item.icon})` }}></div>
                  </div>
                  <div className="flex justify-center title">
                    <Typography.Title level={5}>{item.name}</Typography.Title>
                  </div>
                  <div className="flex justify-center">
                    <Space split={<Divider type="vertical" />}>
                      <Button
                        type="link"
                        onClick={() => {
                          window.open(item.manual);
                        }}
                      >
                        手册
                      </Button>
                      {(item.operate_status === 'default' || item.operate_status === 'uninstall' || item.operate_status === 'installing') && (
                        <Button
                          type="link"
                          disabled={checkPermissionDisabled('cloud:service:down') || item.operate_status === 'installing'}
                          loading={item.operate_status === 'installing'}
                          onClick={() => {
                            messageApi.open({ duration: 0, key, type: 'loading', content: '下载中..' });
                            updateItemStatus(item.id, 'installing');

                            ws
                              ?.send({
                                code: 10001,
                                data: { id: item.id, version: item.version },
                              })
                              .then((resp) => {
                                console.log(resp);
                                if (resp.data.success) {
                                  messageApi.open({ key, type: 'success', content: resp.data.successMsg });
                                } else {
                                  messageApi.open({ key, type: 'error', content: resp.data.errorMsg });
                                }

                                updateItemStatus(item.id, resp.data.result?.operate_status);
                              });
                          }}
                        >
                          下载
                        </Button>
                      )}
                    </Space>
                  </div>
                </Card>
              );
            })}
          </div>
        </Card>
      </div>
    </>
  );
}

export default () => (
  <WebSocketProvider>
    <DriveMarket></DriveMarket>
  </WebSocketProvider>
)
