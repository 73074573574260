import { Button, Drawer, Radio, Space } from 'antd';
import React, { Fragment, useState } from 'react';
import {
  AccountBookOutlined,
  AimOutlined,
  AlertOutlined,
  ApartmentOutlined,
  ApiOutlined,
  AppstoreAddOutlined,
  AppstoreOutlined,
  AudioOutlined,
  BankOutlined,
  BarsOutlined,
  BellOutlined,
  BlockOutlined,
  BorderlessTableOutlined,
  BranchesOutlined,
  BugOutlined,
  BuildOutlined,
  CalendarOutlined,
  CameraOutlined,
  CarOutlined,
  CarryOutOutlined,
  ClearOutlined,
  CloudOutlined,
  CloudSyncOutlined,
  CloudDownloadOutlined,
  ClusterOutlined,
  CodeOutlined,
  CommentOutlined,
  CompassOutlined,
  ConsoleSqlOutlined,
  CrownOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  CustomerServiceOutlined,
  DesktopOutlined,
  DeploymentUnitOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  EuroCircleOutlined,
  ExperimentOutlined,
  ExpandOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  FieldTimeOutlined,
  FileExcelOutlined,
  FileTextOutlined,
  FileZipOutlined,
  FilterOutlined,
  FireOutlined,
  FlagOutlined,
  FileUnknownOutlined,
  FormatPainterOutlined,
  FunctionOutlined,
  FundProjectionScreenOutlined,
  FrownOutlined,
  GlobalOutlined,
  GiftOutlined,
  HddOutlined,
  HistoryOutlined,
  HeartOutlined,
  HomeOutlined,
  IdcardOutlined,
  ImportOutlined,
  LaptopOutlined,
  LinkOutlined,
  MacCommandOutlined,
  NotificationOutlined,
  PartitionOutlined,
  PoundCircleOutlined,
  PrinterOutlined,
  QrcodeOutlined,
  RocketOutlined,
  SendOutlined,
  UserOutlined,
  WifiOutlined,
} from '@ant-design/icons';

export const iconJson = {
  AccountBookOutlined,
  AimOutlined,
  AlertOutlined,
  ApartmentOutlined,
  ApiOutlined,
  AppstoreAddOutlined,
  AppstoreOutlined,
  AudioOutlined,
  BankOutlined,
  BarsOutlined,
  BellOutlined,
  BlockOutlined,
  BorderlessTableOutlined,
  BranchesOutlined,
  BugOutlined,
  BuildOutlined,
  CalendarOutlined,
  CameraOutlined,
  CarOutlined,
  CarryOutOutlined,
  ClearOutlined,
  CloudOutlined,
  CloudSyncOutlined,
  CloudDownloadOutlined,
  ClusterOutlined,
  CodeOutlined,
  CommentOutlined,
  CompassOutlined,
  ConsoleSqlOutlined,
  CrownOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  CustomerServiceOutlined,
  DesktopOutlined,
  DeploymentUnitOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  EuroCircleOutlined,
  ExperimentOutlined,
  ExpandOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  FieldTimeOutlined,
  FileExcelOutlined,
  FileTextOutlined,
  FileZipOutlined,
  FilterOutlined,
  FireOutlined,
  FlagOutlined,
  FileUnknownOutlined,
  FormatPainterOutlined,
  FunctionOutlined,
  FundProjectionScreenOutlined,
  FrownOutlined,
  GlobalOutlined,
  GiftOutlined,
  HddOutlined,
  HistoryOutlined,
  HeartOutlined,
  HomeOutlined,
  IdcardOutlined,
  ImportOutlined,
  LaptopOutlined,
  LinkOutlined,
  MacCommandOutlined,
  NotificationOutlined,
  PartitionOutlined,
  PoundCircleOutlined,
  PrinterOutlined,
  QrcodeOutlined,
  RocketOutlined,
  SendOutlined,
  UserOutlined,
  WifiOutlined,
};

const ButtonShow = ({ value, onClick, disabled }: { value?: string; onClick: () => void; disabled?: boolean }) => {
  // @ts-ignore
  const IconElement = iconJson[value];
  if (disabled) return <Fragment key="disabled">{IconElement ? <IconElement /> : '未选择icon'}</Fragment>;
  if (!value)
    return (
      <Button key="select" onClick={onClick}>
        选择icon图标
      </Button>
    );
  if (!IconElement)
    return (
      <Button key="select" onClick={onClick}>
        选择icon图标
      </Button>
    );
  return (
    <Button key="change" onClick={onClick}>
      切换icon图标<IconElement></IconElement>
    </Button>
  );
};

const IconSelect = ({ value, onChange, disabled }: { value?: string; onChange?: (value: string) => void; disabled?: boolean }) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <Drawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        title="图标选择icon"
      >
        <Radio.Group
          onChange={(e) => {
            onChange?.(e.target.value);
            setOpen(false);
          }}
          value={value}
        >
          <Space wrap>
            {Object.entries(iconJson).map(([key]) => {
              // @ts-ignore
              const IconElement = iconJson[key];
              return (
                <Radio value={key} key={key}>
                  <IconElement />
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
      </Drawer>
      {/* <Button
        onClick={() => {
          setOpen(true);
        }}
      >
        选择icon图表
      </Button> */}
      <ButtonShow
        value={value}
        disabled={disabled}
        onClick={() => {
          setOpen(true);
        }}
      ></ButtonShow>
    </>
  );
};

export default IconSelect;
