import { getUserProfile, putProfile, putPwdSet } from '@/api/system/user';
import CommonLayout from '@/components/CommonLayout';
import Upload from '@/components/Upload';
import useFormHooks from '@/hooks/useForm';
import { Button, Form, Input, Space, Tabs } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ResetPwd = () => {
  const navigate = useNavigate();
  const { form, onFinish } = useFormHooks({
    update: {
      // @ts-ignore
      ajax: putPwdSet,
      cbk: () => {
        const timeId = setTimeout(() => {
          window.localStorage.clear();
          navigate('/auth/login');
          clearTimeout(timeId);
        }, 800);
      },
    },
  });
  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Form.Item label="旧密码" name="oldPassword" rules={[{ required: true, message: '请输入旧密码' }]}>
        <Input.Password placeholder="请输入"></Input.Password>
      </Form.Item>
      <Form.Item label="新密码" name="password" rules={[{ required: true, message: '请输入新密码' }]}>
        <Input.Password placeholder="请输入"></Input.Password>
      </Form.Item>
      <Form.Item
        label="确认新密码"
        name="newPassword"
        dependencies={['password']}
        rules={[
          { required: true, message: '请输入确认新密码' },
          ({ getFieldValue }) => {
            return {
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('请确认两次输入新密码相同'));
              },
            };
          },
        ]}
      >
        <Input.Password placeholder="请输入"></Input.Password>
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            type="primary"
            onClick={async () => {
              const values = await form.validateFields();
              onFinish(values);
            }}
          >
            保存
          </Button>
          <Button
            type="primary"
            ghost
            onClick={() => {
              form.resetFields();
            }}
          >
            取消
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const UserProfile = () => {
  useEffect(() => {
    localStorage.setItem('title', '个人中心');
    document.title = '个人中心';
  }, []);

  const [preData, setPreData] = useState<Record<string, any>>({});
  return (
    <CommonLayout
      breadcrumb={{
        title: '个人中心',
        describe: '个人中心页面提供个人信息查询、密码修改等功能。',
      }}
      mainType="form"
      ajaxJson={{
        get: {
          // @ts-ignore
          ajax: getUserProfile,
          transform: (values) => {
            const { roles, user } = values;
            return { ...user, role_name: roles?.[0]?.roleName, created: dayjs(user?.created).format('YYYY-MM-DD'), editType: 'detail', avatar: user?.avatar };
          },
          cbk: (data) => {
            setPreData(data);
          },
        },
        update: {
          // @ts-ignore
          ajax: putProfile,
        },
      }}
      mainFormChildren={
        <>
          <Tabs
            items={[
              {
                key: 'info',
                label: '个人信息',
                children: (
                  <>
                    <Form.Item noStyle shouldUpdate={(pre, now) => pre.editType !== now.editType}>
                      {(form) => {
                        const editType = form.getFieldValue('editType');
                        if (editType === 'detail') {
                          return (
                            <>
                              <Form.Item label="用户头像" name="avatar" key="readonly-avatar">
                                <Upload disabled={true}></Upload>
                              </Form.Item>
                              <Form.Item label="用户名称" name="username" key="readonly-username">
                                <Input readOnly bordered={false}></Input>
                              </Form.Item>
                              <Form.Item label="手机号" name="phone" key="readonly-phone">
                                <Input readOnly bordered={false}></Input>
                              </Form.Item>
                              <Form.Item label="用户邮箱" name="email" key="readonly-email">
                                <Input readOnly bordered={false}></Input>
                              </Form.Item>
                            </>
                          );
                        }
                        return (
                          <>
                            <Form.Item label="用户头像" name="avatar" key="edit-avatar">
                              <Upload></Upload>
                            </Form.Item>
                            <Form.Item label="用户名称" name="username" key="edit-username" rules={[{ required: true, message: '请输入用户名称' }]}>
                              <Input placeholder="请输入"></Input>
                            </Form.Item>
                            <Form.Item label="手机号" name="phone" key="edit-phone" rules={[{ required: true, message: '请输入手机号' }]}>
                              <Input placeholder="请输入"></Input>
                            </Form.Item>
                            <Form.Item label="用户邮箱" name="email" key="edit-email" rules={[{ required: true, message: '请输入用户邮箱' }]}>
                              <Input placeholder="请输入"></Input>
                            </Form.Item>
                          </>
                        );
                      }}
                    </Form.Item>

                    <Form.Item label="所属部门" name={['dept', 'dept_name']}>
                      <Input readOnly bordered={false}></Input>
                    </Form.Item>
                    <Form.Item label="所属角色" name="role_name">
                      <Input readOnly bordered={false}></Input>
                    </Form.Item>
                    <Form.Item label="创建时间" name="created">
                      <Input readOnly bordered={false}></Input>
                    </Form.Item>
                    <Form.Item shouldUpdate={(pre, now) => pre.editType !== now.editType}>
                      {(form) => {
                        const editType = form.getFieldValue('editType');
                        console.log({ editType });
                        if (editType === 'detail')
                          return (
                            <Form.Item>
                              <Space>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    form.setFieldValue('editType', 'edit');
                                  }}
                                  key="edit"
                                >
                                  编辑
                                </Button>
                              </Space>
                            </Form.Item>
                          );
                        return (
                          <Form.Item>
                            <Space>
                              <Button type="primary" htmlType="submit" key="save">
                                保存
                              </Button>
                              <Button
                                type="primary"
                                ghost
                                onClick={() => {
                                  form.setFieldsValue(preData);
                                  // form.setFieldValue('editType', 'detail');
                                }}
                              >
                                取消
                              </Button>
                            </Space>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </>
                ),
              },
              {
                key: 'pwd',
                label: '修改密码',
                children: <ResetPwd></ResetPwd>,
              },
            ]}
          ></Tabs>
        </>
      }
    ></CommonLayout>
  );
};

export default UserProfile;
