import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { UserOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Layout, Space, Menu, Avatar, Dropdown, Modal, Form, Input, MenuProps, message, Spin } from 'antd';
// import { menus } from '@/routes/menus';
import './style.less';
import { clearUser } from '@/utils/auth';
import { changePassword } from '@/api';
import useUserInfo, { getLocalStorage, useSystem } from '@/hooks/useUserInfo';
import { iconJson } from '@/pages/system/Menu/components/IconSelect';

const { Header, Content, Sider } = Layout;
const { useForm } = Form;
const { useMessage } = message;

export interface LyaoutProps {}

type MenuResItem = {
  menuName: string;
  id: string;
  path: string;
  menuType: string;
  jumpLink: string;
  children?: MenuResItem[];
  icon?: string;
};

const Lyaout: React.FC<LyaoutProps> = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [headerSelectKey, setHeaderSelectKey] = useState<string>('');

  const [messageApi, contextHolder] = useMessage();

  const location = useLocation();
  const navigate = useNavigate();
  // console.log('console', location, navigate);
  // const menusRef = useRef<MenuResItem[]>([]);
  // const { getMenuData, getUserData } = useUserInfo();
  // const [userInfo] = useState(getUserData());
  // useEffect(() => {
  //   menuList = getMenuData() || [];
  // }, []);

  const { userInfoLoading, userInfo, menuList } = useUserInfo();

  // const headerMenus = useMemo(() => {
  //   return menus!.map((item) => {
  //     const { key, label, icon } = item as SubMenuType
  //     return { key, label, icon }
  //   })
  // }, [])

  const siderMenus = useMemo(() => {
    // console.log(menuList);

    const mapTree = (item: MenuResItem): any => {
      const haveChildren = Array.isArray(item.children) && !!item.children?.length && item.menuType !== 'C';
      // @ts-ignore
      const IconElement = iconJson[item.icon];
      return {
        title: item.menuName,
        label: item.menuName,
        key: item.path || item.id,
        id: item.id,
        path: item.path,
        ...(IconElement ? { icon: <IconElement /> } : {}),
        ...(item.menuType === 'C' ? {} : { children: haveChildren ? item?.children?.map?.((i) => mapTree(i)) : undefined }),
      };
    };

    return menuList?.map((item) => mapTree(item));
    // const headerMenus = menuList!.find(item => {
    //   const { key } = item as SubMenuType
    //   return key === headerSelectKey
    // }) as SubMenuType

    // return headerMenus?.children || []
  }, [headerSelectKey, menuList]);

  const [sliderMenuOpenKeys, setSliderMenuOpenKeys] = useState<string[]>([]);

  useEffect(() => {
    // const { paths } = menuList?.reduce(
    //   (r, menu) => {
    //     if (Array.isArray(menu.children)) {
    //       const findItem = menu.children.find((item) => item.path === location.pathname);
    //       // console.log('findItem', findItem);
    //       if (findItem) {
    //         r.paths.push(menu.path);
    //         // r.push(menu.key, findItem.key)
    //         return r;
    //       }
    //     }
    //     // if (menu.key === location.pathname) {
    //     //   r.push(menu.key)
    //     // }
    //     return r;
    //   },
    //   { paths: [] } as { paths: string[] },
    // );
    // const { titles } = menuList?.reduce(
    //   (r, menu) => {
    //     console.log(location.pathname);
    //     if (location.pathname === '/auth/login') {
    //       r.titles.push('登录');
    //     } else if (menu.path === location.pathname) {
    //       r.titles.push(menu.menuName);
    //     } else if (Array.isArray(menu.children)) {
    //       const findItem = menu.children.find((item) => item.path === location.pathname);
    //       // console.log('findItem', findItem);
    //       if (findItem) {
    //         r.titles.push(findItem.menuName || menu.menuName);
    //         // r.push(menu.key, findItem.key)
    //         return r;
    //       }
    //     }
    //     // if (menu.key === location.pathname) {
    //     //   r.push(menu.key)
    //     // }
    //     return r;
    //   },
    //   { titles: [] } as { titles: string[] },
    // );
    // // console.log(paths, titles);
    // if (titles[titles.length - 1]) {
    //   document.title = titles[titles.length - 1];
    //   localStorage.setItem('title', titles[titles.length - 1]);
    // } else {
    //   document.title = localStorage.getItem('title') || '';
    // }
    // console.log({ paths });
    const menuMap = getLocalStorage('$MenuMap$') || {};
    const [, first] = location.pathname.split('/');
    console.log({ first }, location.pathname.split('/'));
    setSliderMenuOpenKeys([`/${first}`]);
    if (menuMap[location.pathname]?.menuName) {
      document.title = menuMap[location.pathname].menuName;
    }
  }, [location.pathname]);

  useEffect(() => {
    const moduleRoute = location.pathname.match(/(?<!\w)\/\w+/g);
    if (Array.isArray(moduleRoute)) {
      setHeaderSelectKey(moduleRoute[0]);
    }
  }, []);

  if (location.pathname === '/auth/login') {
    return <Outlet></Outlet>;
  }
  const [form] = useForm();
  const handleOk = async () => {
    const values = await form.validateFields();
    console.log('values', values);
    values.newPassword1 = undefined;
    changePassword(values).then((resp) => {
      if (resp.success) {
        messageApi.open({ type: 'success', content: '修改成功' });
        setIsModalOpen(false);
        setTimeout(() => {
          navigate('/auth/login');
        }, 1000);
      } else {
        messageApi.open({ type: 'error', content: resp.errorMsg });
      }
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { systemData } = useSystem();

  const items: MenuProps['items'] = [
    {
      key: 'password',
      label: (
        <>
          <div
            onClick={() => {
              navigate('/common/userProfile');
            }}
          >
            个人中心
          </div>
          <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText="确定" cancelText="取消" maskClosable={false}>
            {contextHolder}
            <Form form={form} layout="vertical" style={{ marginTop: '40px' }}>
              <Form.Item label="原密码" name="currentPassword" rules={[{ required: true, message: '请输入' }]}>
                <Input placeholder="请输入原密码" />
              </Form.Item>
              <Form.Item label="新密码" name="newPassword" rules={[{ required: true, message: '请输入' }]}>
                <Input.Password placeholder="请输入新密码" />
              </Form.Item>
              <Form.Item label="确认密码" name="newPassword1" rules={[{ required: true, message: '请输入' }]}>
                <Input.Password placeholder="请输入确认密码" />
              </Form.Item>
            </Form>
          </Modal>
        </>
      ),
    },
    {
      key: 'logout',
      label: (
        <div
          onClick={() => {
            clearUser();
            navigate('/auth/login');
          }}
        >
          退出登录
        </div>
      ),
    },
  ];

  return (
    <Layout className="main-layout">
      <Header className="main-header">
        <Space size={20}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/home/dashboard');
            }}
          >
            <img src={systemData?.system_logo_file} alt="" style={{ marginTop: '15px', marginLeft: '-20px', height: '25px', float: 'left' }} />
            <div style={{ fontSize: '14px', marginLeft: '20px', fontWeight: 600 }}>{systemData?.system_name}</div>
          </div>
          {/* <Menu
            mode="horizontal"
            items={headerMenus}
            selectedKeys={[headerSelectKey]}
            onClick={({ key }) => {
              setHeaderSelectKey(key)
            }}
          /> */}
          {/* <Badge className="header-message" color="geekblue" dot>
            <MailOutlined />
          </Badge> */}
          <div></div>
          <QuestionCircleOutlined
            onClick={() => {
              window.open('https://doc.hummingbird.winc-link.com/');
            }}
          />
          <Dropdown menu={{ items }} placement="bottom" arrow={{ pointAtCenter: true }} trigger={['click']}>
            <Spin spinning={userInfoLoading}>
              <Space>
                <Avatar src={userInfo?.avatar} icon={<UserOutlined />} size="small" style={{ cursor: 'pointer' }} />
                <div style={{ cursor: 'pointer' }}>{userInfo?.nickName}</div>
              </Space>
            </Spin>
          </Dropdown>
        </Space>
      </Header>
      <Layout className="main-body" hasSider>
        <Sider width={200} className="main-sider" theme="light" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
          <Menu
            mode="inline"
            style={{ height: '100%', borderRight: 0 }}
            // @ts-ignore
            items={siderMenus}
            openKeys={sliderMenuOpenKeys}
            onOpenChange={(openKeys) => {
              setSliderMenuOpenKeys(openKeys);
            }}
            selectedKeys={[location.pathname]}
            onClick={({ key }) => {
              const menuMap = getLocalStorage('$MenuMap$') || {};
              if (menuMap[key]?.jumpLink === '1') {
                window.open(key, 'target');
                return;
              }
              navigate(key);
            }}
          ></Menu>
        </Sider>
        <Layout className="main-container" style={{ marginLeft: collapsed ? 80 : 200 }}>
          <Content className="main-view">
            <Outlet></Outlet>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Lyaout;
