import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Spin, Upload as UploadBase, type UploadFile, message as antMessage, Modal } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import React, { useEffect, useState } from 'react';
// import { postChatGroupImageUpload } from 'src/services/userData/personal';
import { postUpload } from '@/api/system/set';

const filePath = process.env.REACT_APP_FILEPATH;
const Upload = ({
  value,
  onChange,
  maxCount = 1,
  rules,
  onError,
  disabled,
  showMessage,
  className,
}: {
  value?: string | string[];
  onChange?: (v: string | string[]) => void;
  maxCount?: number;
  rules?: {
    type: 'size' | 'type';
    max?: number;
    fileType?: string[];
    message?: string;
    acceptList?: string[];
  }[];
  onError?: (message: string) => void;
  disabled?: boolean;
  showMessage?: string;
  className?: string;
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    console.log({ value });
    const list = Array.isArray(value) ? value : value ? [value] : [];
    list.length
      ? setFileList(
          // @ts-ignore
          (Array.isArray(value) ? value : [value]).map((k) => ({
            name: k,
            uid: k,
            thumbUrl: `${k}`,
          })),
        )
      : setFileList([]);
  }, [value]);

  const handleChange = ({ fileList: newFileList, file: { status } }: UploadChangeParam<UploadFile<any>>) => {
    console.log(newFileList, status);
    if (!status) return;
    if (status === 'removed') {
      onChange?.(maxCount > 1 ? newFileList.map(({ name }) => name) : newFileList.map(({ name }) => name).join(','));
      return;
    }
    setFileList(newFileList);
  };

  // const { modal, message: antMessage } = App.useApp();

  const beforeUpload = ({ size, type }: RcFile) => {
    // console.log(file);
    console.log('beforeUpload');
    if (rules?.length) {
      for (let index = 0; index < rules.length; index++) {
        const { type: ruleType, max, message = '文件上传错误', acceptList } = rules[index];
        console.log(type);
        if (ruleType === 'type' && !acceptList?.includes(type)) {
          onError?.(message);
          antMessage.error(message);
          return false;
        }
        if (ruleType === 'size' && size > (max as number)) {
          onError?.(message);
          antMessage.error(message);
          return false;
        }
      }
    }
  };

  const [spinning, setSpinning] = useState<boolean>(false);
  // @ts-ignore
  const customRequest = async ({ file }) => {
    console.log('customRequest');
    // console.log(file);
    setSpinning(true);
    try {
      const res = await postUpload({}, file);
      console.log(res);
      /** 如果是相同的图片，就直接修改fileList */
      if (maxCount === 1 && value && res?.result?.url && value === res?.result?.url) {
        setFileList([
          {
            name: value as string,
            uid: value as string,
            thumbUrl: `${filePath}${value}`,
          },
        ]);
      }
      // @ts-ignore
      onChange?.(maxCount > 1 ? [...(value || []), res?.result?.url] : res?.result?.url);
    } catch {
    } finally {
      setSpinning(false);
    }
  };

  const onPreview = ({ thumbUrl }: UploadFile) => {
    Modal.info({
      title: '预览',
      icon: null,
      closeIcon: <CloseOutlined />,
      content: (
        <div style={{ marginLeft: '34px', textAlign: 'center' }}>
          <img src={thumbUrl} alt="" style={{ width: '100%', display: 'block', margin: '0 auto', borderRadius: '6px' }}></img>
        </div>
      ),
      // footer: null,
      okText: '关闭',
      centered: true,
      maskClosable: true,
      width: '50%',
    });
  };

  console.log({ disabled });

  return (
    <Spin spinning={spinning}>
      <UploadBase
        listType="picture-card"
        fileList={fileList}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        maxCount={maxCount}
        onPreview={onPreview}
        accept={'.png, .jpg, .jpeg'}
        disabled={!!disabled}
        className={className || ''}
        progress={undefined}
      >
        {!disabled && !fileList?.length && (
          <div className="common-button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>上传</div>
          </div>
        )}
      </UploadBase>
      <div style={{ color: 'gray' }}>{showMessage}</div>
    </Spin>
  );
};

export default Upload;
