import { deleteDept, getDept, getDeptDetail, getDeptTree, postDept, putDept } from '@/api/system/dept';
import CommonLayout, { CommonLayoutInstance } from '@/components/CommonLayout';
import { Form, Input, Space, InputNumber, Radio, TreeSelect } from 'antd';
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';

const Dept = () => {
  const CommonLayoutRef = useRef<CommonLayoutInstance<{ params: {}; model: {}; drawerParams: {}; drawerModel: {} }>>(null);
  const [deptTree, setDeptTree] = useState<{ id?: string; label?: string; children?: { id?: string; label?: string }[] }[]>([]);
  const getDeptTreeList = async () => {
    try {
      const res = await getDeptTree();
      setDeptTree([{ id: '0', label: '根目录', children: res.result || [] }]);
    } catch {
    } finally {
      console.log('finally');
    }
  };

  useEffect(() => {
    getDeptTreeList();
  }, []);

  return (
    <CommonLayout
      ref={CommonLayoutRef}
      breadcrumb={{
        title: '部门管理',
        describe: '部门管理页面提供数据筛选、新增部门、删除等功能模块，用于管理平台菜单。',
      }}
      searchFormChildren={
        <Space>
          <Form.Item name="dept_name">
            <Input placeholder="部门名称" allowClear></Input>
          </Form.Item>
        </Space>
      }
      columns={[
        // {
        //   dataIndex: 'parent_id',
        //   title: '上级部门',
        //   render: (v) => (!v ? '根目录' : v),
        // },
        {
          dataIndex: 'dept_name',
          title: '部门名称',
        },
        {
          dataIndex: 'leader',
          title: '负责人',
        },
        {
          dataIndex: 'phone',
          title: '电话',
        },
        {
          dataIndex: 'email',
          title: '邮箱',
        },
        {
          dataIndex: 'status',
          title: '状态',
          // render: (v: number) => ({ 1: '启用', 2: '禁用' })[v],

          // render: (v: number) => <Switch checkedChildren="启用" unCheckedChildren="禁用" checked={v === 1} />,
          render: (value: number) => {
            if (value === 2)
              return (
                <Space>
                  <CloseCircleFilled style={{ color: '#ef8e62' }} />
                  禁用
                </Space>
              );
            if (value === 1)
              return (
                <Space>
                  <CheckCircleFilled style={{ color: '#52c41a' }} />
                  启用
                </Space>
              );
            return '-';
          },
        },
        {
          dataIndex: 'sort',
          title: '排序',
        },
        {
          dataIndex: 'created',
          title: '创建时间',
          render: (v) => dayjs(v).format('YYYY-MM-DD HH:mm:ss'),
        },
      ]}
      ajaxJson={{
        get: {
          ajax: getDept,
          transform: (list) => ({ list }),
          isNotToSetValues: true,
        },
        // @ts-ignore
        delete: { ajax: deleteDept },
        getTree: { ajax: getDeptTree },
      }}
      tableProps={{ pagination: false }}
      commonCbk={(key) => {
        if (key !== 'get') {
          getDeptTree();
          CommonLayoutRef.current?.reload();
        }
      }}
      drawerJson={{
        ajaxJson: {
          edit: {
            // @ts-ignore
            ajax: putDept,
            convertValue: ({ parent_id, ...rest }) => {
              return { ...rest, parent_id: parent_id === '0' ? '' : parent_id };
            },
          },
          add: {
            // @ts-ignore
            ajax: postDept,
            convertValue: ({ parent_id, ...rest }) => {
              return { ...rest, parent_id: parent_id === '0' ? '' : parent_id };
            },
            cbk: () => {},
          },

          get: {
            // @ts-ignore
            ajax: getDeptDetail,
            transform: ({ parent_id, ...rest }) => {
              return { ...rest, parent_id: parent_id || '0' };
            },
          },
        },
        commonCbk: (key) => {
          if (key !== 'get') {
            getDeptTree();
            CommonLayoutRef.current?.reload();
          }
        },
        formProps: { layout: 'vertical' },
        renderFormChildren: ({ ajaxKey, isDetail: disabled }) => (
          <Fragment key={ajaxKey || 'edit'}>
            <Form.Item name="parent_id" label="上级部门" rules={[{ required: true }]}>
              <TreeSelect treeData={deptTree} disabled={disabled} fieldNames={{ label: 'label', value: 'id', children: 'children' }} treeDefaultExpandAll></TreeSelect>
            </Form.Item>
            <Form.Item name="dept_name" label="部门名称" rules={[{ required: true }]}>
              <Input disabled={disabled} placeholder="请输入"></Input>
            </Form.Item>
            <Form.Item name="leader" label="负责人" rules={[{ required: true }]}>
              <Input disabled={disabled} placeholder="请输入"></Input>
            </Form.Item>
            <Form.Item name="phone" label="电话" rules={[{ required: true }]}>
              <Input disabled={disabled} placeholder="请输入"></Input>
            </Form.Item>
            <Form.Item name="email" label="邮箱" rules={[{ required: true }]}>
              <Input disabled={disabled} placeholder="请输入"></Input>
            </Form.Item>
            <Form.Item name="status" label="状态">
              <Radio.Group disabled={disabled}>
                <Radio value={1}>启用</Radio>
                <Radio value={2}>禁用</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="sort" label="排序" rules={[{ required: true }]}>
              <InputNumber style={{ width: '100%' }} disabled={disabled}></InputNumber>
            </Form.Item>
            <Form.Item name="id" noStyle>
              <div></div>
            </Form.Item>
          </Fragment>
        ),
      }}
      operateButtonList={[
        {
          name: '编辑',
          ajaxKey: 'edit',
          props: { type: 'primary' },
          isToShowDrawer: true,
          sortData: ({ id }) => ({ id }),
          isToReload: true,
          permission: 'admin:sysDept:edit',
        },
        {
          name: '删除',
          ajaxKey: 'delete',
          props: { type: 'primary', danger: true },
          isToPopConfirm: true,
          isToAjax: true,
          popconfirmProps: { title: '是否确认删除该部门?' },
          sortData: ({ id }) => ({ id }),
          permission: 'admin:sysDept:remove',
        },
      ]}
      leftOperateButtonList={[
        {
          name: '新增',
          permission: 'admin:sysDept:add',
          ajaxKey: 'add',
          props: { type: 'primary' },
          isToShowDrawer: true,
          drawerProps: { title: '新增' },
          sortData: () => ({ parent_id: '0', status: 1 }),
        },
      ]}
    ></CommonLayout>
  );
};
export default Dept;
