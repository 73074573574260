import { deleteLog, getLog } from '@/api/system/log';
import CommonLayout, { CommonLayoutInstance } from '@/components/CommonLayout';
import { Space, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useRef } from 'react';

const Log = () => {
  const CommonLayoutRef = useRef<CommonLayoutInstance<{ params: {}; model: {}; drawerParams: {}; drawerModel: {} }>>(null);

  return (
    <CommonLayout
      ref={CommonLayoutRef}
      queryPermission="admin:sysLoginLog:query"
      breadcrumb={{
        title: '登入日志',
        describe: '登入日志页面提供用户登入列表展示功能模块。',
      }}
      isHideDrawer
      searchFormChildren={
        <Space>
          <Form.Item name="username">
            <Input placeholder="用户名称" allowClear></Input>
          </Form.Item>
          <Form.Item name="log_location">
            <Input placeholder="登录地点" allowClear></Input>
          </Form.Item>
          <Form.Item name="ipaddr">
            <Input placeholder="登录地址" allowClear></Input>
          </Form.Item>
          <Form.Item name="status">
            <Select
              allowClear
              style={{ width: '120px' }}
              placeholder="登录状态"
              options={[
                { label: '成功', value: '2' },
                { label: '失败', value: '1' },
              ]}
            ></Select>
          </Form.Item>
        </Space>
      }
      columns={[
        {
          dataIndex: 'id',
          title: '访问编号',
        },
        {
          dataIndex: 'username',
          title: '用户名称',
        },
        {
          dataIndex: 'ipaddr',
          title: '登录地址',
        },
        {
          dataIndex: 'login_location',
          title: '登录地点',
        },
        {
          dataIndex: 'browser',
          title: '浏览器',
        },
        {
          dataIndex: 'os',
          title: '操作系统',
        },
        {
          dataIndex: 'status',
          title: '登录状态',
          render: (v: number) => ({ 1: '失败', 2: '成功' })[v],
        },
        {
          dataIndex: 'msg',
          title: '操作信息',
        },
        {
          dataIndex: 'created',
          title: '登录时间',
          render: (v) => dayjs(v).format('YYYY-MM-DD HH:mm:ss'),
        },
      ]}
      ajaxJson={{
        get: {
          // @ts-ignore
          ajax: getLog,
          isNotToSetValues: true,
        },

        delete: {
          // @ts-ignore
          ajax: deleteLog,
          convertValue: (data) => {
            console.log({ data });
            return data;
          },
        },
      }}
      commonCbk={(key) => {
        if (key !== 'get') {
          CommonLayoutRef.current?.reload();
        }
      }}
      rightOperateButtonList={[
        {
          name: '删除',
          ajaxKey: 'delete1',
          // isToPopConfirm: true,
          props: { type: 'primary', danger: true, disabled: true },
          // popconfirmProps: { disabled: true, title: '是否确认删除选中日志' },
          filter: (data) => !!data?.length,
        },
        {
          name: '删除',
          ajaxKey: 'delete',
          sortData: (ids) => ({ ids }),
          isToPopConfirm: true,
          isToAjax: true,
          props: { type: 'primary', danger: true },
          popconfirmProps: { title: '是否确认删除选中日志' },
          filter: (data) => !data?.length,
          permission: 'admin:sysLoginLog:batchRemove',
        },
      ]}
      hasRowSelection={true}
    ></CommonLayout>
  );
};
export default Log;
