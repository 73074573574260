import { getDeptTree } from '@/api/system/dept';
import { getMenuTree } from '@/api/system/menu';
import { deleteRole, getRole, getRoleDetail, postRole, putRole } from '@/api/system/role';
import CommonLayout, { CommonLayoutInstance } from '@/components/CommonLayout';
import { Form, Input, Space, Select, Radio, TreeSelect, InputNumber } from 'antd';
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';

const Role = () => {
  const CommonLayoutRef = useRef<CommonLayoutInstance<{ params: {}; model: {}; drawerParams: {}; drawerModel: {} }>>(null);
  const [menuTree, setMenuTree] = useState<{ id?: string; label?: string; children?: { id?: string; label?: string }[] }[]>([]);
  const [deptTree, setDeptTree] = useState<{ id?: string; label?: string; children?: { id?: string; label?: string }[] }[]>([]);
  const getMenuTreeList = async () => {
    try {
      const res = await getMenuTree();
      setMenuTree(res.result || []);
    } catch {
    } finally {
      console.log('finally');
    }
  };

  const getDeptTreeList = async () => {
    try {
      const res = await getDeptTree();
      setDeptTree(res.result || []);
    } catch {
    } finally {
      console.log('finally');
    }
  };

  useEffect(() => {
    getMenuTreeList();
    getDeptTreeList();
  }, []);

  const TreeSelectRef = useRef(null);

  return (
    <CommonLayout
      ref={CommonLayoutRef}
      queryPermission="admin:sysRole:query"
      breadcrumb={{
        title: '角色管理',
        describe: '角色管理页面提供数据筛选、新增角色、数据列表等功能模块，用于管理平台角色。',
      }}
      columns={[
        {
          dataIndex: 'roleName',
          title: '角色名称',
        },
        {
          dataIndex: 'roleKey',
          title: '角色标识',
        },
        {
          dataIndex: 'status',
          title: '状态',
          // render: (v: number) => ({ 1: '正常', 2: '禁用' })[v],
          // render: (v: number) => <Switch checkedChildren="正常" unCheckedChildren="禁用" checked={v === 1} />,
          render: (value: string) => {
            if (value === '2')
              return (
                <Space>
                  <CloseCircleFilled style={{ color: '#ef8e62' }} />
                  禁用
                </Space>
              );
            if (value === '1')
              return (
                <Space>
                  <CheckCircleFilled style={{ color: '#52c41a' }} />
                  正常
                </Space>
              );
            return '-';
          },
        },
        {
          dataIndex: 'roleSort',
          title: '排序',
        },
        {
          dataIndex: 'created',
          title: '创建时间',
          render: (v) => dayjs(v).format('YYYY-MM-DD HH:mm:ss'),
        },
      ]}
      ajaxJson={{
        get: {
          // @ts-ignore
          ajax: getRole,
          isNotToSetValues: true,
        },
        // @ts-ignore
        delete: { ajax: deleteRole },
      }}
      // tableProps={{ pagination: false }}
      commonCbk={(key) => {
        if (key !== 'get') {
          CommonLayoutRef.current?.reload();
        }
      }}
      searchFormChildren={
        <Space>
          <Form.Item name="roleName">
            <Input placeholder="名称" allowClear></Input>
          </Form.Item>
          <Form.Item name="status">
            <Select
              style={{ width: '120px' }}
              placeholder="状态"
              allowClear
              options={[
                { value: '1', label: '正常' },
                { value: '2', label: '禁用' },
              ]}
            ></Select>
          </Form.Item>
        </Space>
      }
      drawerJson={{
        ajaxJson: {
          edit: {
            // @ts-ignore
            ajax: putRole,
          },
          add: {
            // @ts-ignore
            ajax: postRole,
            cbk: () => {},
          },

          get: {
            // @ts-ignore
            ajax: getRoleDetail,
          },
        },
        commonCbk: (key) => {
          if (key !== 'get') {
            CommonLayoutRef.current?.reload();
          }
        },
        formProps: { layout: 'vertical' },
        renderFormChildren: ({ ajaxKey, isDetail: disabled }) => (
          <Fragment key={ajaxKey || 'edit'}>
            <Form.Item name="roleName" label="角色名称" rules={[{ required: true }]}>
              <Input placeholder="请输入" disabled={disabled}></Input>
            </Form.Item>
            <Form.Item name="roleKey" label="角色标识" rules={[{ required: true }]}>
              <Input placeholder="请输入" disabled={disabled}></Input>
            </Form.Item>
            <Form.Item name="menuIds" label="菜单权限" rules={[{ required: true }]}>
              <TreeSelect
                placeholder="请选择"
                showCheckedStrategy={TreeSelect.SHOW_ALL}
                treeDefaultExpandAll
                treeCheckable
                treeData={menuTree}
                fieldNames={{ label: 'label', value: 'id', children: 'children' }}
                disabled={disabled}
                ref={TreeSelectRef}
                onChange={(...args) => {
                  console.log(args);
                  console.log(TreeSelectRef.current);
                }}
              ></TreeSelect>
            </Form.Item>
            <Form.Item name="dataScope" label="数据权限" rules={[{ required: true }]}>
              <Select
                disabled={disabled}
                options={[
                  { label: '全部数据权限', value: '0' },
                  { label: '自定义数据权限', value: '1' },
                  { label: '本部门数据权限', value: '2' },
                  { label: '本部门及一下数据权限', value: '3' },
                  { label: '仅本人数据权限', value: '4' },
                ]}
                placeholder="请选择"
              ></Select>
            </Form.Item>
            <Form.Item noStyle dependencies={['dataScope']}>
              {(form) => {
                const dataScope = form.getFieldValue('dataScope');
                if (dataScope !== '1') {
                  return <></>;
                }
                return (
                  <Form.Item name="deptIds" label="部门权限" rules={[{ required: true }]}>
                    <TreeSelect
                      treeData={deptTree}
                      disabled={disabled}
                      treeDefaultExpandAll
                      treeCheckable
                      fieldNames={{ label: 'label', value: 'id', children: 'children' }}
                      placeholder="请选择"
                    ></TreeSelect>
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item name="roleSort" label="排序" rules={[{ required: true }]}>
              <InputNumber placeholder="请输入" style={{ width: '100%' }} disabled={disabled}></InputNumber>
            </Form.Item>
            <Form.Item name="status" label="状态" rules={[{ required: true }]}>
              <Radio.Group
                disabled={disabled}
                options={[
                  { value: '1', label: '正常' },
                  { value: '2', label: '禁用' },
                ]}
              ></Radio.Group>
            </Form.Item>
            <Form.Item name="remark" label="备注">
              <Input.TextArea placeholder="请输入" disabled={disabled}></Input.TextArea>
            </Form.Item>
            <Form.Item name="id" noStyle>
              <div></div>
            </Form.Item>
          </Fragment>
        ),
      }}
      operateButtonList={[
        {
          name: '编辑',
          ajaxKey: 'edit',
          props: { type: 'primary' },
          isToShowDrawer: true,
          sortData: ({ id }) => ({ id }),
          isToReload: true,
          permission: 'admin:sysRole:update',
          sortItem: ({ roleKey }) => (roleKey === 'super_admin' ? { props: { type: 'primary', disabled: true } } : { props: { type: 'primary' } }),
        },
        {
          name: '删除',
          ajaxKey: 'delete',
          props: { type: 'primary', danger: true },
          isToPopConfirm: true,
          isToAjax: true,
          popconfirmProps: { title: '是否确认删除该角色?' },
          sortData: ({ id }) => ({ id }),
          permission: 'admin:sysRole:remove',
          sortItem: ({ roleKey }) => (roleKey === 'super_admin' ? { props: { type: 'primary', disabled: true } } : { props: { type: 'primary' } }),
        },
      ]}
      leftOperateButtonList={[
        { name: '新增', permission: 'admin:sysRole:add', ajaxKey: 'add', props: { type: 'primary' }, isToShowDrawer: true, drawerProps: { title: '新增' }, sortData: () => ({ status: '1' }) },
      ]}
    ></CommonLayout>
  );
};
export default Role;
