// @ts-ignore
/* eslint-disable */
import { request } from 'src/utils/request';

/** 获取角色详情 GET /api/v1/system-setup */
export async function getSystem(params: {}, options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/system-setup', {
    method: 'GET',
    params: { ...params },
    ...(options || {}),
  });
}

/** 更改用户状态 PUT /api/v1/status */
export async function putSystem(body: object, options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/system-setup', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 新增角色 POST /api/v1/img/upload */
export async function postUpload(body: {}, file?: File, options?: { [key: string]: any }) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      formData.append(ele, typeof item === 'object' && !(item instanceof File) ? JSON.stringify(item) : item);
    }
  });
  return request<Record<string, any>>('/api/v1/image/upload', {
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...(options || {}),
  });
}
