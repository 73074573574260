import { useEffect, useState } from 'react';

const useAuthButton = () => {
  const [permissions, setPermissions] = useState<string[]>([]);
  useEffect(() => {
    try {
      // @ts-ignore
      setPermissions(JSON.parse(window.localStorage.getItem('$UserInfo$')).permissions);
    } catch {}
  }, []);
  const checkPermissionDisabled = (permission?: string) => {
    if (!permission || permissions?.includes('*:*:*') || permissions?.includes(permission)) return false;
    return true;
  };
  return { permissions, checkPermissionDisabled };
};

export default useAuthButton;
