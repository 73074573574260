import { getUserGetinfo, getMenuRole } from '@/api/system/user';
import { useEffect, useState } from 'react';
import { getToken } from '@/utils/auth';
import { getSystem } from '@/api/system/set';

const setLocalStorage = (key: string, value: object) => localStorage.setItem(key, JSON.stringify(value));
export const getLocalStorage = (key: string) => {
  try {
    // @ts-ignore
    return JSON.parse(localStorage.getItem(key));
  } catch {
    return undefined;
  }
};

type MenuResItem = {
  menuName: string;
  id: string;
  path: string;
  menuType: string;
  jumpLink: string;
  children?: MenuResItem[];
};

function loop(arr: MenuResItem[], json: Record<string, MenuResItem>) {
  arr?.forEach((item) => {
    const { path, menuName, children, ...rest } = item;
    json[path] = { ...rest, path, menuName };
    if (Array.isArray(children)) {
      loop(children as MenuResItem[], json);
    }
  });
}

const useUserInfo = () => {
  // const [userInfo] = useState(getLocalStorage('$UserInfo$'));

  const [userInfo, setUserInfo] = useState<Record<string, any>>({});
  const [userInfoLoading, setUserInfoLoading] = useState<boolean>(false);

  const [menuList, setMenuList] = useState<MenuResItem[]>([]);
  const [menuLoading, setMenuLoading] = useState<boolean>(false);

  const getUserInfo = async () => {
    setUserInfoLoading(true);
    try {
      const res = await getUserGetinfo();
      setUserInfo(res.result);
      // console.log({ res });
      setLocalStorage('$UserInfo$', res.result || {});
    } catch {
    } finally {
      setUserInfoLoading(false);
    }
  };

  const getMenuInfo = async () => {
    setMenuLoading(true);
    try {
      const res = await getMenuRole();
      console.log({ res });
      setMenuList(res.result || []);
      setLocalStorage('$MenuRole$', res.result || []);
      const json = {};
      loop(res.result || [], json);
      setLocalStorage('$MenuMap$', json);
    } catch {
    } finally {
      setMenuLoading(false);
    }
  };

  const getInitialData = async () => {
    Promise.all([getUserInfo(), getMenuInfo()]);
  };

  useEffect(() => {
    if (getToken()) {
      getInitialData();
    }
  }, []);

  const getMenuData = () => getLocalStorage('$MenuRole$');

  const getUserData = () => getLocalStorage('$UserInfo$');

  return { getInitialData, getMenuData, getUserData, userInfo, userInfoLoading, menuList, menuLoading };
};

const handleIcoCreate = (icoUrl: string) => {
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  // @ts-ignore
  link.type = 'image/x-icon';
  // @ts-ignore
  link.rel = 'shortcut icon';
  // @ts-ignore
  link.href = icoUrl;
  document.getElementsByTagName('head')[0].appendChild(link);
};

export const useSystem = () => {
  const [systemData, setSystemData] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(false);

  const getSystemData = async () => {
    // console.log(getLocalStorage('$systemData$'));
    if (getLocalStorage('$systemData$')) {
      const res = getLocalStorage('$systemData$') || {};
      handleIcoCreate(res?.system_browser_label);
      setSystemData(res || {});
      return;
    }
    setLoading(false);
    try {
      const res = await getSystem({});
      handleIcoCreate(res.result?.system_browser_label);
      setSystemData(res.result || {});
      setLocalStorage('$systemData$', res.result || []);
    } catch {
    } finally {
      setLoading(true);
    }
  };

  useEffect(() => {
    getSystemData();
  }, []);

  return { loading, systemData };
};

export default useUserInfo;
