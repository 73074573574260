// @ts-ignore
/* eslint-disable */
import { request } from 'src/utils/request';

/** 获取所有菜单 GET /api/v1/menu */
export async function getMenu(params: { menuName?: string; title?: string }, options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/menu', {
    method: 'GET',
    params: { ...params },
    ...(options || {}),
  });
}

/** 更新菜单 PUT /api/v1/menu */
export async function putMenu(
  body: {
    id: string;
    menuName: string;
  },
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/api/v1/menu', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查看部门详情 GET /api/v1/menu/24636618 */
export async function getMenuDetail(params: { id: string }, options?: { [key: string]: any }) {
  return request<Record<string, any>>(`/api/v1/menu/${params.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });
}

/** 创建菜单 POST /api/v1/menu */
export async function postMenu(
  body: {
    menuName: string;
    title: string;
    icon: string;
    path: string;
    menuType: string;
    permission: string;
    parentId: string;
    sort: number;
    visible: string;
    isFrame: string;
  },
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/api/v1/menu', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除菜单 DELETE /api/v1/menu/70027914 */
export async function deleteMenu(body: { id: string }, options?: { [key: string]: any }) {
  return request<Record<string, any>>(`/api/v1/menu/${body.id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });
}

/** 根据角色获取菜单树 GET /api/v1/menurole */
export async function getMenurole(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/menurole', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 查看菜单列表 Copy GET /api/v1/menu-tree */
export async function getMenuTree(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/menu-lab', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    // data: body,
    ...(options || {}),
  });
}
