// @ts-ignore
/* eslint-disable */
import { request } from 'src/utils/request';

/** 查看部门列表 GET /api/v1/dept */
export async function getDept(params?: { status?: string }, options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/dept', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    // data: body,
    ...(options || {}),
  });
}

/** 查询部门详情 PUT /api/v1/dept */
export async function putDept(
  body: {
    id: string;
    parent_id: string;
    dept_name: string;
    sort: number;
    leader: string;
    phone: string;
    email: string;
    status: number;
  },
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/api/v1/dept', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 添加部门 POST /api/v1/dept */
export async function postDept(
  body: {
    parent_id: string;
    dept_name: string;
    sort: number;
    leader: string;
    phone: string;
    email: string;
    status: number;
  },
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/api/v1/dept', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查看部门列表 Copy GET /api/v1/dept-tree */
export async function getDeptTree(params?: { status?: string }, options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/dept-tree', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    // data: body,
    ...(options || {}),
  });
}

/** 查看部门详情 GET /api/v1/dept/24636618 */
export async function getDeptDetail(params: { id: number }, options?: { [key: string]: any }) {
  return request<Record<string, any>>(`/api/v1/dept/${params.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });
}

/** 删除部门 DELETE /api/v1/dept/59621257 */
export async function deleteDept(params: { id?: number }, options?: { [key: string]: any }) {
  return request<Record<string, any>>(`/api/v1/dept/${params.id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });
}
