import { Form, message } from 'antd';
import type { CascaderProps, FormInstance, FormItemProps, InputProps, SelectProps } from 'antd';
import { Rule } from 'antd/es/form';
import React, { useState, useEffect, useReducer } from 'react';

export type IAjaxJson = {
  [key in 'get' | 'update' | 'add' | string]?: {
    ajax: (params?: { [key: string]: any }) => Promise<void | any>; // 接口函数
    cbk?: (values: any, responseValues?: any) => void; // 接口成功后回调
    params?: { [key: string]: any }; // 一些数据
    transform?: (values: any, rest?: { params?: any; res?: any }) => any; // 掉接口后格式化数据
    convertValue?: (values: any) => any; // 掉接口前格式化数据
    operateName?: string; // 操作名称
    isNotToSetValues?: boolean;
    isToConfirm?: boolean; // 是否显示确认狂
    /** 不要显示成功message */
    isNotToShowSuccessMsg?: boolean;
    successMsgText?: string;
    beforeFun?: () => void;
    finallyFun?: () => void;
  };
};

export type IAjaxItem = IAjaxJson[keyof IAjaxJson];
// export interface IAjaxItem<GetVo = any, PostVo = any> {}

export type UseFormSettings = {
  /** 初始化的时候不要调用get接口 */
  isNotToInitial?: boolean;
  commonCbk?: (ajaxKey: string, values: any, responseValues?: any) => void;
};

export interface IUseFormHooks {
  <GetVo = any, PostVo = any>(
    v1: IAjaxJson,
    v2?: UseFormSettings,
  ): {
    form: FormInstance<PostVo | GetVo>;
    spinning: boolean;
    onFinish: (values: PostVo, type?: keyof IAjaxJson, ajaxItem?: Partial<IAjaxItem>) => Promise<void>;
    loading: boolean;
    reload: (p?: any) => Promise<void>;
    getAjaxByKey: (type: keyof IAjaxJson, p?: any) => Promise<void>;
    changeSpinning: (p: boolean) => void;
  };
}

// const useAjaxHooks = () => {};

const useFormHooks: IUseFormHooks = (ajaxJson, settings) => {
  const [form] = Form.useForm();
  const [spinning, setSpinning] = useState<boolean>(false);

  const getInitialValues = async (p?: any) => {
    const { get } = ajaxJson;
    const { ajax, params: baseParams, cbk, transform, convertValue, isNotToSetValues = false } = get || {};
    if (!ajax) return;
    setSpinning(true);
    try {
      const params = convertValue?.({ ...baseParams, ...p }) || { ...baseParams, ...p };
      const res = await ajax(params);
      const data = transform?.(res.result, { params }) || res.result;
      if (!isNotToSetValues) form.setFieldsValue(data);
      cbk?.(data, res.result);
      settings?.commonCbk?.('get', data, res.result);
    } catch (e) {
      // @ts-ignore
      // message.error(e?.message);
    } finally {
      setSpinning(false);
    }
  };

  useEffect(() => {
    if (!settings?.isNotToInitial) getInitialValues();
    // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: any, key?: keyof typeof ajaxJson, ajaxItem?: Partial<IAjaxItem>) => {
    const { ajax, cbk, convertValue, transform, operateName = '操作', isNotToShowSuccessMsg, successMsgText } = { ...(ajaxJson[key || 'update'] || {}), ...ajaxItem };
    if (!ajax) return;
    setLoading(true);
    try {
      const res = await ajax(convertValue?.(values) || values);
      const data = transform?.(res.result) || res.result;
      cbk?.(data, res.result);
      if (!isNotToShowSuccessMsg) message.success(successMsgText || `${operateName}成功`);
      settings?.commonCbk?.(key || 'update', data, res.result);
    } catch (e) {
      // @ts-ignore
      // message.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const getAjaxByKey = async (key?: keyof typeof ajaxJson, values?: any) => {
    console.log('getAjaxByKey', key);
    const { ajax, cbk, convertValue, transform, beforeFun, finallyFun } = ajaxJson[key || 'update'] || {};
    if (!ajax) return;
    beforeFun?.();
    try {
      const res = await ajax(convertValue?.(values) || values);
      const data = transform?.(res.result) || res.result;
      cbk?.(data, res.result);
      settings?.commonCbk?.(key || 'update', data, res.result);
    } catch (e) {
      console.log(e, message);
      // @ts-ignore
      // message.error(e.message);
    } finally {
      finallyFun?.();
    }
  };

  const changeSpinning = (status: boolean) => {
    setSpinning(status);
  };

  return {
    form,
    spinning,
    onFinish,
    loading,
    reload: getInitialValues,
    getAjaxByKey,
    changeSpinning,
  };
};

type IState = {
  options?: Record<string, any>[];
  rules?: Rule[];
  formItemProps?: FormItemProps;
  fieldProps?: SelectProps | InputProps | CascaderProps<object>;
};

export function useFormJsonReducer<T>(initialValues: { [key in keyof T]: IState }) {
  const [formJson, dispatch] = useReducer<React.Reducer<{ [key in keyof T]: IState }, { key: keyof T; value: IState }[]>>((state, values) => {
    const json = { ...state };
    for (const { key, value } of values) {
      json[key] = { ...json[key], ...value };
    }
    return json;
  }, initialValues);
  return { formJson, dispatch };
}

export type IUseFormJson = typeof useFormJsonReducer;

// type IJson = (typeof useFormJsonReducer)[keyof typeof useFormJsonReducer];

export default useFormHooks;
