// @ts-ignore
/* eslint-disable */
import { request } from 'src/utils/request';

/** 获取日志 GET sys-login-log */
export async function getLog(params: { username?: string; loginLocation?: string; status?: string; ipaddr: string }, options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/sys-login-log', {
    method: 'GET',
    params: { ...params },
    ...(options || {}),
  });
}
export async function deleteLog(body: { ids: string[] }, options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/sys-login-log', {
    method: 'DELETE',
    data: body,
    ...(options || {}),
  });
}
