import { getSystem, putSystem } from '@/api/system/set';
import CommonLayout, { CommonLayoutInstance } from '@/components/CommonLayout';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import React, { useRef } from 'react';
import Upload from '@/components/Upload';
import useAuthButton from '@/hooks/useAuthButton';
import styles from './index.module.less';

const SysSet = () => {
  const { checkPermissionDisabled } = useAuthButton();
  const CommonLayoutRef = useRef<CommonLayoutInstance<{ params: {}; model: {}; drawerParams: {}; drawerModel: {} }>>(null);
  return (
    <CommonLayout
      ref={CommonLayoutRef}
      breadcrumb={{
        title: '系统配置',
        describe: '系统设置页面提供系统基础配置项，支持用户对系统的名称、登录页、LOGO等基础信息进行维护管理。',
      }}
      mainType="form"
      ajaxJson={{
        get: {
          // @ts-ignore
          ajax: getSystem,
        },
        update: {
          // @ts-ignore
          ajax: putSystem,
          operateName: '保存',
          successMsgText: '保存成功，即将刷新页面',
          cbk: () => {
            window.localStorage.removeItem('$systemData$');
            setTimeout(() => {
              window.location.reload();
            }, 500);
          },
        },
      }}
      mainFormChildren={
        <>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="id" noStyle style={{}}>
                <div></div>
              </Form.Item>
              <Form.Item label="系统名称" name="system_name" rules={[{ required: true, message: '请输入系统名称' }]}>
                <Input placeholder="请输入" disabled={checkPermissionDisabled('admin:sysSet:update')}></Input>
              </Form.Item>
              <Form.Item label="copyright" name="copyright" rules={[{ required: true, message: '请输入copyright' }]}>
                <Input placeholder="请输入" disabled={checkPermissionDisabled('admin:sysSet:update')}></Input>
              </Form.Item>
              <Row>
                <Col span={12}>
                  <Form.Item label="系统logo" name="system_logo_file" rules={[{ required: true, message: '请选择系统logo' }]}>
                    <Upload className={styles['left-upload']} showMessage="推荐尺寸200*200" maxCount={1} disabled={checkPermissionDisabled('admin:sysSet:update')}></Upload>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="浏览器标签" name="system_browser_label" rules={[{ required: true, message: '请选择浏览器标签' }]}>
                    <Upload className={styles['left-upload']} maxCount={1} showMessage="推荐尺寸：64*64" disabled={checkPermissionDisabled('admin:sysSet:update')}></Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Form.Item label="背景图" name="system_background_image" rules={[{ required: true, message: '请选择背景图' }]}>
                <Upload className={styles['right-upload']} maxCount={1} showMessage="推荐尺寸：800*600" disabled={checkPermissionDisabled('admin:sysSet:update')}></Upload>
              </Form.Item>
            </Col>
          </Row>
          {!checkPermissionDisabled('admin:sysSet:update') && (
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" loading={!!CommonLayoutRef.current?.loading}>
                  保存
                </Button>
              </Space>
            </Form.Item>
          )}
        </>
      }
    ></CommonLayout>
  );
};

export default SysSet;
