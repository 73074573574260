import { getRole } from '@/api/system/role';
import { getDeptTree } from '@/api/system/dept';
import { deleteUser, getUser, getUserDetail, postUser, putUser, putPwdReset, putStatus } from '@/api/system/user';
import CommonLayout, { CommonLayoutInstance } from '@/components/CommonLayout';
import { Form, Input, Space, Select, Radio, TreeSelect } from 'antd';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';

const User = () => {
  const CommonLayoutRef = useRef<CommonLayoutInstance<{ params: {}; model: {}; drawerParams: {}; drawerModel: {} }>>(null);
  const [roleTree, setRoleTree] = useState<{ id?: string; label?: string; children?: { id?: string; label?: string }[] }[]>([]);
  const [deptTree, setDeptTree] = useState<{ id?: string; label?: string; children?: { id?: string; label?: string }[] }[]>([]);

  const getDeptTreeList = async () => {
    try {
      const res = await getDeptTree({ status: '1' });
      setDeptTree(res.result || []);
    } catch {
    } finally {
      console.log('finally');
    }
  };
  const getRoleList = async () => {
    try {
      const res = await getRole({ isAll: true, status: '1' });
      setRoleTree(res.result?.list || []);
    } catch {
    } finally {
      console.log('finally');
    }
  };
  useEffect(() => {
    getRoleList();
    getDeptTreeList();
  }, []);

  return (
    <CommonLayout
      ref={CommonLayoutRef}
      queryPermission="admin:sysUser:query"
      breadcrumb={{
        title: '用户管理',
        describe: '用户管理页面提供数据筛选、新增用户、重置密码、用户数据列表等功能模块。',
      }}
      columns={[
        {
          dataIndex: 'username',
          title: '用户名',
        },
        {
          dataIndex: 'nickName',
          title: '昵称',
        },
        {
          dataIndex: 'phone',
          title: '手机',
        },
        {
          dataIndex: 'dept',
          title: '部门',
          render: (dept) => dept?.dept_name,
        },
        {
          dataIndex: 'status',
          title: '状态',
          // render: (v: number) => ({ 1: '禁用', 2: '正常' })[v],
          // render: (v: number) => <Switch checkedChildren="正常" unCheckedChildren="禁用" checked={v === 2} />,
          render: (value: string) => {
            if (value === '1')
              return (
                <Space>
                  <CloseCircleFilled style={{ color: '#ef8e62' }} />
                  禁用
                </Space>
              );
            if (value === '2')
              return (
                <Space>
                  <CheckCircleFilled style={{ color: '#52c41a' }} />
                  正常
                </Space>
              );
            return '-';
          },
        },
        {
          dataIndex: 'created',
          title: '创建时间',
          render: (v) => dayjs(v).format('YYYY-MM-DD HH:mm:ss'),
        },
      ]}
      ajaxJson={{
        get: {
          // @ts-ignore
          ajax: getUser,
          isNotToSetValues: true,
        },
        // @ts-ignore
        delete: { ajax: deleteUser },
        putStatus2: {
          // @ts-ignore
          ajax: putStatus,
          convertValue: (data) => ({ ...data, status: '2' }),
        },
        putStatus1: {
          // @ts-ignore
          ajax: putStatus,
          convertValue: (data) => ({ ...data, status: '1' }),
        },
      }}
      // tableProps={{ pagination: false }}
      commonCbk={(key) => {
        if (key !== 'get') {
          CommonLayoutRef.current?.reload();
        }
      }}
      searchFormChildren={
        <Space>
          <Form.Item name="username">
            <Input placeholder="名称" allowClear key="search-username"></Input>
          </Form.Item>
          <Form.Item name="phone">
            <Input placeholder="手机" allowClear key="search-phone"></Input>
          </Form.Item>
          <Form.Item name="status">
            <Select
              style={{ width: '120px' }}
              placeholder="状态"
              allowClear
              options={[
                { value: '2', label: '正常' },
                { value: '1', label: '禁用' },
              ]}
            ></Select>
          </Form.Item>
          <Form.Item name="deptId">
            <TreeSelect
              key="search-deptId"
              allowClear
              treeDefaultExpandAll
              style={{ width: '200px' }}
              placeholder="部门"
              treeData={deptTree}
              fieldNames={{ label: 'label', value: 'id', children: 'children' }}
            ></TreeSelect>
          </Form.Item>
        </Space>
      }
      drawerJson={{
        ajaxJson: {
          edit: {
            // @ts-ignore
            ajax: putUser,
          },
          add: {
            // @ts-ignore
            ajax: postUser,
            cbk: () => {},
          },

          get: {
            // @ts-ignore
            ajax: getUserDetail,
          },
          putPwdReset: {
            // @ts-ignore
            ajax: putPwdReset,
          },
        },
        commonCbk: (key) => {
          if (key !== 'get') {
            CommonLayoutRef.current?.reload();
          }
        },
        formProps: { layout: 'vertical' },

        formChildrenJson: {
          putPwdReset: (
            <Fragment key="putPwdReset">
              <Form.Item name="password" label="新密码">
                <Input.Password placeholder="请输入"></Input.Password>
              </Form.Item>
              <Form.Item name="userId" noStyle>
                <div></div>
              </Form.Item>
            </Fragment>
          ),
        },
        renderFormChildren: ({ ajaxKey, isDetail: disabled }) => (
          <Fragment key={ajaxKey || 'edit'}>
            <Form.Item name="username" label="用户名称" rules={[{ required: true }]}>
              <Input placeholder="请输入" disabled={disabled}></Input>
            </Form.Item>
            <Form.Item name="nickName" label="用户昵称" rules={[{ required: true }]}>
              <Input placeholder="请输入" disabled={disabled}></Input>
            </Form.Item>
            <Form.Item name="deptId" label="部门" rules={[{ required: true }]}>
              <TreeSelect placeholder="请选择" disabled={disabled} treeDefaultExpandAll treeData={deptTree} fieldNames={{ label: 'label', value: 'id', children: 'children' }}></TreeSelect>
            </Form.Item>
            <Form.Item name="phone" label="手机号" rules={[{ required: true }]}>
              <Input placeholder="请输入" disabled={disabled}></Input>
            </Form.Item>
            <Form.Item name="email" label="邮箱" rules={[{ required: true }]}>
              <Input placeholder="请输入" disabled={disabled}></Input>
            </Form.Item>
            <Form.Item dependencies={['id']} noStyle>
              {(form) => {
                const id = form.getFieldValue('id');
                if (!id)
                  return (
                    <Form.Item name="password" label="密码" rules={[{ required: true }]}>
                      <Input.Password placeholder="请输入" disabled={disabled}></Input.Password>
                    </Form.Item>
                  );
                return <></>;
              }}
            </Form.Item>

            <Form.Item name="sex" label="性别" rules={[{ required: true }]}>
              <Radio.Group
                disabled={disabled}
                options={[
                  { label: '男', value: '男' },
                  { label: '女', value: '女' },
                ]}
              ></Radio.Group>
            </Form.Item>
            <Form.Item name="roleId" label="角色" rules={[{ required: true }]}>
              <Select disabled={disabled} placeholder="请选择" options={roleTree} fieldNames={{ label: 'roleName', value: 'id' }}></Select>
            </Form.Item>
            <Form.Item name="status" label="状态" rules={[{ required: true }]}>
              <Radio.Group
                disabled={disabled}
                options={[
                  { value: '2', label: '正常' },
                  { value: '1', label: '禁用' },
                ]}
              ></Radio.Group>
            </Form.Item>
            <Form.Item name="remark" label="备注">
              <Input.TextArea placeholder="请输入" disabled={disabled}></Input.TextArea>
            </Form.Item>
            <Form.Item name="id" noStyle>
              <div></div>
            </Form.Item>
          </Fragment>
        ),
      }}
      operateButtonList={[
        {
          name: '编辑',
          ajaxKey: 'edit',
          props: { type: 'primary' },
          isToShowDrawer: true,
          sortData: ({ id }) => ({ id }),
          isToReload: true,
          permission: 'admin:sysUser:edit',
          sortItem: ({ username }) => (username === 'super_admin' ? { props: { type: 'primary', disabled: true } } : { props: { type: 'primary' } }),
        },
        {
          name: '重置密码',
          ajaxKey: 'putPwdReset',
          isToShowDrawer: true,
          sortData: ({ id }) => ({ userId: id }),
          permission: 'admin:sysUser:passwordSet',
          sortItem: ({ username }) => (username === 'super_admin' ? { props: { type: 'primary', disabled: true } } : { props: { type: 'primary' } }),
        },
        {
          name: '禁用',
          ajaxKey: 'putStatus1',
          isToPopConfirm: true,
          isToAjax: true,
          sortData: ({ id }) => ({ userId: id }),
          filter: ({ status }) => status === '1',
          popconfirmProps: { title: '是否确认禁用用该用户?' },
          permission: 'admin:sysUser:edit',
          sortItem: ({ username }) => (username === 'super_admin' ? { props: { type: 'primary', disabled: true } } : { props: { type: 'primary' } }),
        },
        {
          name: '启用',
          ajaxKey: 'putStatus2',
          isToPopConfirm: true,
          isToAjax: true,
          sortData: ({ id }) => ({ userId: id }),
          filter: ({ status }) => status === '2',
          popconfirmProps: { title: '是否确认启用该用户?' },
          permission: 'admin:sysUser:edit',
          sortItem: ({ username }) => (username === 'super_admin' ? { props: { type: 'primary', disabled: true } } : { props: { type: 'primary' } }),
        },
        {
          name: '删除',
          ajaxKey: 'delete',
          props: { type: 'primary', danger: true },
          isToPopConfirm: true,
          isToAjax: true,
          popconfirmProps: { title: '是否确认删除该用户?' },
          sortData: ({ id }) => ({ id }),
          permission: 'admin:sysUser:remove',
          sortItem: ({ username }) => (username === 'super_admin' ? { props: { type: 'primary', disabled: true } } : { props: { type: 'primary' } }),
        },
      ]}
      leftOperateButtonList={[
        {
          name: '新增',
          permission: 'admin:sysUser:add',
          ajaxKey: 'add',
          props: { type: 'primary' },
          isToShowDrawer: true,
          drawerProps: { title: '新增' },
          sortData: () => ({ status: '2', sex: '男' }),
        },
      ]}
    ></CommonLayout>
  );
};
export default User;
