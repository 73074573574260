// @ts-ignore
/* eslint-disable */
import { request } from 'src/utils/request';

/** 重置用户密码 PUT /api/v1/pwd/reset */
export async function putPwdReset(
  body: {
    userId: string;
    password: string;
  },
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/api/v1/pwd/reset', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 用户修改密码 PUT /api/v1/pwd/set */
export async function putPwdSet(
  body: {
    // userId: string;
    oldPassword: string;
    newPassword: string;
  },
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/api/v1/pwd/set', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 更改用户状态 PUT /api/v1/status */
export async function putStatus(
  body: {
    userId: string;
    status: string;
  },
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/api/v1/status', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 登入日志 GET /api/v1/sys-login-log */
export async function getSysLoginLog(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/sys-login-log', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取用户列表 GET /api/v1/sys-user */
export async function getUser(params: { deptId?: string; username?: string; phone?: string; status?: string }, options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/sys-user', {
    method: 'GET',
    params: { ...params },
    ...(options || {}),
  });
}

/** 查看部门详情 GET /api/v1/menu/24636618 */
export async function getUserDetail(params: { id: string }, options?: { [key: string]: any }) {
  return request<Record<string, any>>(`/api/v1/sys-user/${params.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });
}

/** 用户退出 POST /api/v1/sys-user */
export async function postUser(
  body: {
    username: string;
    password: string;
    nick_name: string;
    phone: string;
    role_id: string;
    avatar: string;
    sex: string;
    email: string;
    dept_id: string;
    remark: string;
    status: string;
  },
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/api/v1/sys-user', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 用户退出 POST /api/v1/sys-user */
export async function putUser(
  body: {
    username: string;
    password: string;
    nick_name: string;
    phone: string;
    role_id: string;
    avatar: string;
    sex: string;
    email: string;
    dept_id: string;
    remark: string;
    status: string;
  },
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/api/v1/sys-user', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除用户 DELETE /api/v1/sys-user/06820074 */
export async function deleteUser(
  body: {
    id?: string;
  },
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>(`/api/v1/sys-user/${body.id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取用户信息 GET /api/v1/user/getinfo */
export async function getUserGetinfo(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/user/getinfo', {
    method: 'GET',
    ...(options || {}),
  });
}
/** 获取用户信息 GET /api/v1/user/getinfo */
export async function getMenuRole(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/menurole', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 个人中心 GET /api/v1/user/profile */
export async function getUserProfile(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/user/profile', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 更改用户状态 PUT /api/v1/status */
export async function putProfile(body: Record<string, any>, options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/user/profile', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
