import React, { memo, useImperativeHandle, useRef, useState } from 'react';
import { AdvanceBreadcrumb } from '../Breadcrumb';
import { Card, Form, Table, Space, Button, type FormProps, type FormInstance, ButtonProps, Drawer, Popconfirm, PopconfirmProps, DrawerProps, Spin, Typography, ConfigProvider } from 'antd';
import useFormHooks, { IAjaxJson } from '@/hooks/useForm';
import { ColumnType, TableProps } from 'antd/es/table';
import { merge } from 'lodash';
import useAuthButton from '@/hooks/useAuthButton';

declare module 'react' {
  function forwardRef<T, P = {}>(render: (props: P, ref: React.Ref<T>) => React.ReactElement | null): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

type PageParamsType = { page: number; pageSize: number; total: number };

export interface CommonLayoutInstance<T extends { params: object; model: object }> {
  /** 刷新 */
  reload: (p?: T['params'] & PageParamsType) => void;
  loading: boolean;
  spinning: boolean;
  formRef: FormInstance<T['params']>;
}

type OperateButtonType = {
  key?: string;
  ajaxKey: string;
  name: string;
  props?: ButtonProps;
  isToShowDrawer?: boolean;
  isToPopConfirm?: boolean;
  popconfirmProps?: PopconfirmProps;
  isToReload?: boolean;
  title?: string;
  sortData?: (data?: any) => any;
  isToAjax?: boolean;
  drawerProps?: DrawerProps;
  sortItem?: (data?: any, item?: OperateButtonType) => Partial<OperateButtonType>;
  filter?: (data?: any) => boolean;
  permission?: string;
};

export interface CommonDrawerInstance<T extends { params: object; model: object }> {
  open: boolean;
  show: (params: T['params'], settings: { title?: string; ajaxKey: string; isToReload?: boolean; isDetail?: boolean }) => void;
  hide: () => void;
  loading: boolean;
  spinning: boolean;
  formRef: FormInstance<T['params']>;
}
type CommonDrawerType = {
  ajaxJson?: IAjaxJson;
  formChildren?: React.ReactNode;
  formChildrenJson?: { [key: string]: React.ReactNode };
  renderFormChildren?: (props: { ajaxKey: string; isDetail: boolean }) => React.ReactNode;
  formProps?: FormProps;
  commonCbk?: (ajaxKey: string, values: any, responseValues?: any) => void;
};

const { Text } = Typography;

function DrawerWithoutRef<T extends { params: object; model: object }>(
  { ajaxJson, formChildren, formProps, commonCbk, formChildrenJson, renderFormChildren }: CommonDrawerType,
  ref: React.ForwardedRef<CommonDrawerInstance<T>>,
) {
  const [open, setOpen] = useState<boolean>(false);
  const { form, spinning, loading, reload, onFinish } = useFormHooks<T['model'], T['params']>(
    merge(
      {
        get: {
          ajax: () => Promise.resolve([]),
        },
      },
      ajaxJson,
    ),
    {
      isNotToInitial: true,
      commonCbk: (...args) => {
        const [key] = args;
        commonCbk?.(...args);
        if (key !== 'get') {
          hide();
        }
      },
    },
  );

  const [ajaxKey, setAjaxKey] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  const [isDetail, setIsDetail] = useState<boolean>(false);

  const show = (params: T['params'], settings: { title?: string; ajaxKey: string; isToReload?: boolean; isDetail?: boolean }) => {
    setOpen(true);
    setTitle(settings?.title || '');
    form?.resetFields();
    setAjaxKey(settings?.ajaxKey);
    setIsDetail(!!settings?.isDetail);
    if (settings?.isToReload) {
      reload(params);
    } else {
      // @ts-ignore
      form?.setFieldsValue(params);
    }
  };

  const hide = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      open,
      reload,
      formRef: form,
      loading,
      show,
      hide,
      spinning,
    };
  });
  return (
    <Drawer
      open={open}
      title={title}
      forceRender
      onClose={() => {
        hide();
      }}
      footer={
        isDetail ? (
          <></>
        ) : (
          <Space>
            <Button
              type="primary"
              onClick={async () => {
                const values = await form.validateFields();
                onFinish(values, ajaxKey);
              }}
              loading={loading}
            >
              确定
            </Button>
            <Button
              onClick={() => {
                hide();
              }}
            >
              取消
            </Button>
          </Space>
        )
      }
    >
      <Spin spinning={spinning}>
        <ConfigProvider theme={{ token: { colorTextDisabled: '#172c4d' } }}>
          <Form layout="vertical" {...formProps} form={form} onFinish={onFinish} name="drawerForm" requiredMark={!isDetail}>
            {formChildrenJson?.[ajaxKey] || formChildren || renderFormChildren?.({ isDetail, ajaxKey })}
          </Form>
        </ConfigProvider>
      </Spin>
    </Drawer>
  );
}

export const CommonDrawer = React.forwardRef(DrawerWithoutRef);

export const CommonButtonList = memo(
  ({ buttonList, onClickBtn, data, isTable }: { buttonList?: OperateButtonType[]; onClickBtn: (item: OperateButtonType, data?: any) => void; data?: any; isTable?: boolean }) => {
    const { checkPermissionDisabled } = useAuthButton();

    return (
      <Space {...(isTable ? { split: <Text type="secondary">/</Text> } : {})}>
        {buttonList
          ?.filter((item) => !item.filter?.(data))
          ?.map((baseItem) => {
            const item = { ...baseItem, ...(baseItem.sortItem?.(data, baseItem) || {}) };
            const disabled = checkPermissionDisabled(item.permission);
            const buttonElement = (
              <Button
                key={item.key || item.ajaxKey}
                {...item.props}
                {...(isTable ? { type: 'link' } : {})}
                disabled={disabled || item.props?.disabled}
                onClick={(e) => {
                  e.preventDefault();
                  if (!item.isToPopConfirm) onClickBtn(item, item.sortData?.(data) || data);
                }}
                style={isTable ? { paddingLeft: 0, paddingRight: 0 } : {}}
              >
                {item.name}
              </Button>
            );
            return item.isToPopConfirm ? (
              <Popconfirm
                title=""
                okText="确定"
                cancelText="取消"
                placement="topRight"
                {...item.popconfirmProps}
                disabled={disabled || item.popconfirmProps?.disabled}
                key={item.key || item.ajaxKey}
                onConfirm={() => {
                  console.log(item.key, item.ajaxKey, item.name);
                  onClickBtn(item, item.sortData?.(data) || data);
                }}
              >
                {buttonElement}
              </Popconfirm>
            ) : (
              buttonElement
            );
          })}
      </Space>
    );
  },
);

function CommonLayoutWithoutRef<T extends { params: object; model: object; drawerParams: object; drawerModel: object }>(
  {
    breadcrumb,
    searchFormChildren,
    columns,
    tableProps,
    searchFormProps,
    ajaxJson,
    operateButtonList,
    leftOperateButtonList,
    rightOperateButtonList,
    drawerJson,
    commonCbk,
    hasRowSelection,
    isHideDrawer,
    mainType = 'table',
    mainFormChildren,
    queryPermission,
  }: {
    breadcrumb: { title: string; describe: string };
    searchFormChildren?: React.ReactNode;
    searchFormProps?: Omit<FormProps, 'onFinish' | 'form' | 'layout'>;
    columns?: ColumnType<T['model']>[];
    tableProps?: Omit<TableProps<T['model']>, 'columns' | 'dataSource' | 'loading'>;
    ajaxJson: IAjaxJson;
    operateButtonList?: OperateButtonType[];
    leftOperateButtonList?: OperateButtonType[];
    rightOperateButtonList?: OperateButtonType[];

    drawerJson?: CommonDrawerType;
    commonCbk?: (ajaxKey: string, values: any, responseValues?: any) => void;
    hasRowSelection?: boolean;
    isHideDrawer?: boolean;
    mainType?: 'table' | 'form';
    mainFormChildren?: React.ReactNode;
    queryPermission?: string;
  },
  ref: React.ForwardedRef<CommonLayoutInstance<T>>,
) {
  const [dataSource, setDataSource] = useState<T['model'][]>([]);

  const [pageParams, setPageParams] = useState<PageParamsType>({
    page: 1,
    pageSize: 20,
    total: 0,
  });

  const { form, spinning, loading, reload, getAjaxByKey, onFinish } = useFormHooks<T['model'], T['params']>(
    merge(
      {
        get:
          mainType === 'table'
            ? {
                ajax: () => Promise.resolve([]),
                isNotToSetValues: true,
                cbk: (values: { list: T['model'][] } & PageParamsType) => {
                  // console.log({ values });
                  const { list, ...rest } = values || {};
                  // @ts-ignore
                  setDataSource(list || []);
                  setPageParams(rest);
                  return {};
                },
              }
            : {
                ajax: () => Promise.resolve([]),
              },
      },
      ajaxJson,
    ),
    {
      commonCbk: (...args) => {
        const [key] = args;
        if (key === 'get') setSelectedRowKeys([]);
        commonCbk?.(...args);
      },
    },
  );

  const triggerToLoadData = (p?: Partial<PageParamsType>) => {
    setPageParams((index) => ({ ...index, ...p }));
    reload(p);
  };

  const CommonDrawerRef = useRef<CommonDrawerInstance<{ params: T['drawerParams']; model: T['drawerModel'] }>>(null);
  useImperativeHandle(ref, () => {
    return {
      reload,
      formRef: form,
      loading,
      spinning,
    };
  });

  const onClickBtn = (item: OperateButtonType, params?: any) => {
    // console.log(item, params);
    if (item.isToShowDrawer) {
      CommonDrawerRef.current?.show(params || {}, { ajaxKey: item.ajaxKey, title: item.name || item.title, isToReload: item.isToReload, isDetail: item.ajaxKey === 'detail' });
      return;
    }
    if (item.isToAjax) {
      console.log('onClickBtn:', item.ajaxKey, params);
      getAjaxByKey(item.ajaxKey, params);
    }
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const { checkPermissionDisabled } = useAuthButton();

  return (
    // eslint-disable-next-line no-template-curly-in-string
    <ConfigProvider form={{ validateMessages: { required: '${label}是必选字段' } }}>
      {!isHideDrawer && <CommonDrawer<{ params: T['drawerParams']; model: T['drawerModel'] }> {...drawerJson} ref={CommonDrawerRef}></CommonDrawer>}
      <AdvanceBreadcrumb {...breadcrumb} background={require('@/assets/images/head-bg.8b029587.png')} />
      {mainType === 'table' && (
        <>
          <Card className="m20" size="small">
            <Form
              layout="inline"
              form={form}
              name="searchForm"
              onFinish={(values) => {
                triggerToLoadData({ ...values, page: 1 });
              }}
              {...searchFormProps}
            >
              {searchFormChildren}
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={spinning} disabled={checkPermissionDisabled(queryPermission)}>
                  查询
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="default"
                  loading={spinning}
                  onClick={() => {
                    form.resetFields();
                    triggerToLoadData({ page: 1 });
                  }}
                >
                  重置
                </Button>
              </Form.Item>
            </Form>
          </Card>

          <Card className="m20" size="small">
            <Space className="mb15 justify-space-between">
              <div>
                <CommonButtonList buttonList={leftOperateButtonList} onClickBtn={onClickBtn}></CommonButtonList>
              </div>
              <div>
                <CommonButtonList buttonList={rightOperateButtonList} onClickBtn={onClickBtn} data={selectedRowKeys}></CommonButtonList>
              </div>
            </Space>
            <Table<T['model']>
              loading={spinning}
              dataSource={dataSource}
              pagination={{
                size: 'small',
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total) => `共 ${total} 条`,
                current: pageParams.page,
                pageSize: pageParams.pageSize,
                total: pageParams.total,
                onChange: (page, pageSize) => {
                  triggerToLoadData({ page, pageSize });
                },
              }}
              bordered
              rowKey="id"
              size="small"
              scroll={{ x: 'max-content' }}
              columns={[
                ...(columns || []),
                ...(operateButtonList?.length
                  ? [
                      {
                        dataIndex: 'operate',
                        title: '操作',
                        render: (_v: any, record: T['model']) => {
                          return (
                            <CommonButtonList
                              isTable
                              buttonList={[
                                {
                                  name: '查看',
                                  ajaxKey: 'detail',
                                  props: { type: 'primary' },
                                  isToShowDrawer: true,
                                  sortData: ({ id }) => ({ id }),
                                  isToReload: true,
                                },
                                ...operateButtonList,
                              ]}
                              onClickBtn={onClickBtn}
                              data={record}
                            ></CommonButtonList>
                          );
                        },
                      },
                    ]
                  : []),
              ]}
              {...(hasRowSelection
                ? {
                    rowSelection: {
                      selectedRowKeys,
                      onChange: (keys) => {
                        setSelectedRowKeys(keys);
                      },
                    },
                  }
                : {})}
              {...tableProps}
            />
          </Card>
        </>
      )}
      {mainType === 'form' && (
        <Card className="m20" size="small" loading={spinning}>
          <ConfigProvider theme={{ token: { colorTextDisabled: '#172c4d' } }}>
            <Form form={form} layout="vertical" name="mainForm" onFinish={onFinish}>
              {mainFormChildren}
            </Form>
          </ConfigProvider>
        </Card>
      )}
    </ConfigProvider>
  );
}

const CommonLayout = React.forwardRef(CommonLayoutWithoutRef);

export default CommonLayout;
