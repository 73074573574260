import { getMenu, postMenu, putMenu, getMenuDetail, deleteMenu, getMenuTree } from '@/api/system/menu';
import CommonLayout, { CommonLayoutInstance } from '@/components/CommonLayout';
import { Space, Form, Input, TreeSelect, InputNumber, Radio, Tag } from 'antd';
import React, { useEffect, useState, useRef, Fragment } from 'react';
import IconSelect from './components/IconSelect';
import dayjs from 'dayjs';

const Menu = () => {
  const CommonLayoutRef = useRef<CommonLayoutInstance<{ params: {}; model: {}; drawerParams: {}; drawerModel: {} }>>(null);
  const [menuTree, setMenuTree] = useState<{ id?: string; label?: string; children?: { id?: string; label?: string }[] }[]>([]);
  const getMenuTreeList = async () => {
    try {
      const res = await getMenuTree();
      setMenuTree([{ id: '0', label: '根目录', children: res.result || [] }]);
    } catch {
    } finally {
      console.log('finally');
    }
  };

  useEffect(() => {
    getMenuTreeList();
  }, []);
  return (
    <CommonLayout
      ref={CommonLayoutRef}
      breadcrumb={{
        title: '菜单管理',
        describe: '菜单管理页面提供数据筛选、新增菜单、菜单配置等功能模块，用于管理平台菜单。',
      }}
      queryPermission="admin:sysMenu:query"
      searchFormChildren={
        <Space>
          <Form.Item name="menuName">
            <Input placeholder="中文名称" allowClear></Input>
          </Form.Item>
          <Form.Item name="title">
            <Input placeholder="国际化key" allowClear></Input>
          </Form.Item>
        </Space>
      }
      columns={[
        {
          dataIndex: 'menuName',
          title: '中文名称',
        },
        {
          dataIndex: 'title',
          title: '国际化key',
        },
        {
          dataIndex: 'permission',
          title: '权限标记',
        },
        {
          dataIndex: 'path',
          title: '路径',
        },
        {
          dataIndex: 'visible',
          title: '隐藏',
          render: (v) => {
            if (v === '0') {
              return <Tag color="green">显示</Tag>;
            }
            if (v === '1') {
              return <Tag color="purple">隐藏</Tag>;
            }
            return <></>;
          },
        },
        {
          dataIndex: 'menuType',
          title: '类型',
          render: (v) => {
            // {/* M为菜单、C为目录、F为按钮 */}
            if (v === 'M') {
              return <Tag color="green">菜单</Tag>;
            }
            if (v === 'C') {
              return <Tag color="purple">目录</Tag>;
            }
            if (v === 'F') {
              return <Tag color="orange">按钮</Tag>;
            }
            return <></>;
          },
        },

        {
          dataIndex: 'created',
          title: '创建时间',
          render: (v) => dayjs(v).format('YYYY-MM-DD HH:mm:ss'),
        },
      ]}
      ajaxJson={{
        get: {
          // @ts-ignore
          ajax: getMenu,
          transform: (list) => ({ list }),
        },
        delete: {
          // @ts-ignore
          ajax: deleteMenu,
        },
      }}
      commonCbk={(key) => {
        if (key !== 'get') {
          getMenuTree();
          CommonLayoutRef.current?.reload();
        }
      }}
      tableProps={{ pagination: false }}
      drawerJson={{
        ajaxJson: {
          edit: {
            // @ts-ignore
            ajax: putMenu,
            convertValue: ({ parentId, ...rest }) => {
              return { ...rest, parentId: parentId === '0' ? '' : parentId };
            },
          },
          add: {
            // @ts-ignore
            ajax: postMenu,
            convertValue: ({ parentId, ...rest }) => {
              return { ...rest, parentId: parentId === '0' ? '' : parentId };
            },
            cbk: () => {},
          },

          get: {
            // @ts-ignore
            ajax: getMenuDetail,
            transform: ({ parentId, ...rest }) => {
              return { ...rest, parentId: parentId || '0' };
            },
          },
        },

        commonCbk: (key) => {
          if (key !== 'get') {
            getMenuTree();
            CommonLayoutRef.current?.reload();
          }
        },
        formProps: { layout: 'vertical' },
        renderFormChildren: ({ ajaxKey, isDetail: disabled }) => (
          <Fragment key={ajaxKey || 'edit'}>
            <Form.Item name="parentId" label="上级菜单" rules={[{ required: true }]}>
              <TreeSelect treeData={menuTree} disabled={disabled} fieldNames={{ label: 'label', value: 'id', children: 'children' }} treeDefaultExpandAll></TreeSelect>
            </Form.Item>
            <Form.Item name="menuName" label="中文名称" rules={[{ required: true }]}>
              <Input disabled={disabled} placeholder="请输入"></Input>
            </Form.Item>
            <Form.Item name="title" label="国际化key" rules={[{ required: true }]}>
              <Input disabled={disabled} placeholder="请输入"></Input>
            </Form.Item>
            <Form.Item name="icon" label="icon">
              {/* <Input></Input> */}
              <IconSelect disabled={disabled}></IconSelect>
            </Form.Item>
            <Form.Item name="sort" label="排序" rules={[{ required: true }]}>
              <InputNumber style={{ width: '100%' }} disabled={disabled} placeholder="请输入"></InputNumber>
            </Form.Item>
            <Form.Item name="menuType" label="类型">
              {/* M为菜单、C为目录、F为按钮 */}
              <Radio.Group
                options={[
                  { label: '菜单', value: 'M' },
                  { label: '目录', value: 'C' },
                  { label: '按钮', value: 'F' },
                ]}
                optionType={disabled ? 'default' : 'button'}
                disabled={disabled}
              />
            </Form.Item>
            <Form.Item noStyle dependencies={['menuType']}>
              {({ getFieldValue }) => {
                const menuType = getFieldValue('menuType');
                if (menuType === 'F') return <></>;
                return (
                  <Form.Item name="jumpLink" label="是否外链">
                    <Radio.Group
                      disabled={disabled}
                      options={[
                        { label: '是', value: '1' },
                        { label: '否', value: '0' },
                      ]}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>

            <Form.Item noStyle dependencies={['menuType', 'jumpLink']}>
              {(form) => {
                const menuType = form.getFieldValue('menuType');
                const jumpLink = form.getFieldValue('jumpLink');
                if (menuType !== 'F' || jumpLink === '1') {
                  return (
                    <Form.Item name="path" label="路径" rules={[{ required: true }]}>
                      <Input disabled={disabled} placeholder="请输入"></Input>
                    </Form.Item>
                  );
                }
                return <></>;
              }}
            </Form.Item>

            <Form.Item name="permission" label="权限">
              <Input disabled={disabled} placeholder="请输入"></Input>
            </Form.Item>
            <Form.Item name="id" noStyle>
              <div></div>
            </Form.Item>
            <Form.Item name="visible" label="状态" rules={[{ required: true }]}>
              <Radio.Group disabled={disabled}>
                <Radio value={'0'}>显示</Radio>
                <Radio value={'1'}>隐藏</Radio>
              </Radio.Group>
            </Form.Item>
          </Fragment>
        ),
      }}
      operateButtonList={[
        {
          name: '编辑',
          ajaxKey: 'edit',
          props: { type: 'primary' },
          isToShowDrawer: true,
          sortData: ({ id }) => ({ id }),
          isToReload: true,
          permission: 'admin:sysMenu:edit',
        },
        {
          name: '删除',
          ajaxKey: 'delete',
          props: { type: 'primary', danger: true },
          isToPopConfirm: true,
          isToAjax: true,
          popconfirmProps: { title: '是否确认删除该菜单?' },
          sortData: ({ id }) => ({ id }),
          permission: 'admin:sysMenu:remove',
        },
      ]}
      leftOperateButtonList={[
        {
          name: '新增',
          ajaxKey: 'add',
          permission: 'admin:sysMenu:add',
          props: { type: 'primary' },
          isToShowDrawer: true,
          drawerProps: { title: '新增' },
          sortData: () => ({ parentId: '0', visible: '0', menuType: 'M' }),
        },
      ]}
    ></CommonLayout>
  );
};
export default Menu;
