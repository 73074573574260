// @ts-ignore
/* eslint-disable */
import { request } from 'src/utils/request';

/** 获取角色详情 GET /api/v1/role */
export async function getRole(params: { isAll?: boolean; roleName?: string; status?: string }, options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/role', {
    method: 'GET',
    params: { ...params },
    ...(options || {}),
  });
}

/** 修改角色 PUT /api/v1/role */
export async function putRole(
  body: {
    role_name: string;
    status: string;
    role_key: string;
    role_sort: number;
    admin: boolean;
    remark: string;
    menuIds: number[];
  },
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/api/v1/role', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 新增角色 POST /api/v1/role */
export async function postRole(
  body: {
    roleName: string;
    status: string;
    roleKey: string;
    roleSort: number;
    admin: boolean;
    remark: string;
    menuIds: number[];
  },
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/api/v1/role', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除角色 DELETE /api/v1/role/00485917 */
export async function deleteRole(body: { id: string }, options?: { [key: string]: any }) {
  return request<Record<string, any>>(`/api/v1/role/${body.id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });
}

/** 查看菜单列表 Copy GET /api/v1/role-lab */
export async function getRoleTree(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/v1/role-lab', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    // data: body,
    ...(options || {}),
  });
}

/** 查看部门详情 GET /api/v1/menu/24636618 */
export async function getRoleDetail(params: { id: string }, options?: { [key: string]: any }) {
  return request<Record<string, any>>(`/api/v1/role/${params.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });
}
