import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';
// import { breadcrumbNameMap } from '@/routes/menus';
import './style.less';
import { getLocalStorage } from '@/hooks/useUserInfo';

export interface NormalBreadcrumbProps {
  // eslint-disable-next-line react/no-unused-prop-types
  children?: React.ReactNode;
}
type MenuItemType = {
  menuName: string;
  path: string;
  children?: MenuItemType[];
};

function loop(arr: MenuItemType[], json: Record<string, MenuItemType>) {
  arr?.forEach((item) => {
    const { path, menuName, children, ...rest } = item;
    json[path] = { ...rest, path, menuName };
    if (Array.isArray(children)) {
      loop(children as MenuItemType[], json);
    }
  });
}

export const useBreadcrumbNameMap = () => {
  const menuMapRef = useRef<Record<string, MenuItemType>>({});
  useEffect(() => {
    try {
      console.log(window.localStorage.getItem('$MenuRole$'));
      // @ts-ignore
      const menuList = JSON.parse(window.localStorage.getItem('$MenuRole$'));
      const json = {};
      loop(menuList, json);
      menuMapRef.current = { ...json };
      console.log(123, json);
    } catch (e) {
      console.log({ e });
    }
  }, []);
  return { menuMapRef };
};

const NormalBreadcrumb: React.FC<NormalBreadcrumbProps> = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);
  // const { menuMapRef } = useBreadcrumbNameMap();

  const menuMap = getLocalStorage('$MenuMap$') || {};

  return (
    <div className="normal-breadcrumb-title">
      <Breadcrumb>
        <Breadcrumb.Item key="home">
          <Link to="/">首页</Link>
        </Breadcrumb.Item>
        {pathSnippets.map((_, index) => {
          const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;

          if (menuMap?.[url]?.menuName) {
            return (
              <Breadcrumb.Item key={url}>
                <Link to={url}>{menuMap?.[url]?.menuName}</Link>
              </Breadcrumb.Item>
            );
          }
          return null;
        })}
      </Breadcrumb>
    </div>
  );
};

export default NormalBreadcrumb;
